<template>
    <div class="btcdirect-widget" style="overflow-y: auto"></div>
</template>

<script>
export default {
    props: ['walletAddress', 'fiatType'],
    mounted() {
        (function (btc, d, i, r, e, c, t) {
        btc[r] = btc[r] || function() {
        (btc[r].q = btc[r].q || []).push(arguments)
        };
        c = d.createElement(i);
        c.id = r; c.src = e; c.async = true;
        c.type = 'module'; c.dataset.btcdirect = '';
        t = d.getElementsByTagName(i)[0];
        t.parentNode.insertBefore(c, t);
        })(window, document, 'script', 'btcdirect',
        'https://cdn.btcdirect.eu/transaction-history/transaction-history.js'
        );

        let returnUrl = new URL(process.env.VUE_APP_XAPP_DEEPLINK)
        const pathname = '/history'
        returnUrl.searchParams.set('path', btoa(pathname))
        returnUrl.searchParams.set('walletAddress', this.walletAddress)
        returnUrl.searchParams.set('fiatType', this.fiatType)

        window.btcdirect('init', { token: process.env.VUE_APP_BTC_DIRECT_TOKEN, theme: window.xAppTheme, returnUrl: returnUrl, useSameWindowForPayment: false });

        window.btcdirect('wallet-addresses', {
            addresses: {
                address: this.walletAddress,
                currency: 'XRP'
            }
        })

        window.btcdirect('include-dca', { include: true })

        window.btcdirect('currencies', {
            crypto: 'XRP',
            fiat: this.fiatType
        })
    }
}
</script>

<style>
.btcdirect-widget-container {
    max-width: none !important;
    height: 100vh !important;
}
</style>
