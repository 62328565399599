import { createRouter, createWebHistory } from 'vue-router'
// createWebHashHistory
import FiatToCoin from '../components/FiatToCoin.vue'
import CoinToFiat from '../components/CoinToFiat.vue'
import LandingPage from '../components/LandingPage.vue'
import TransactionHistory from '../components/TransactionHistory.vue'

const routes = [
    {
        path: '/',
        name: 'pick',
        component: LandingPage
    },
    {
        path: '/buy',
        name: 'buy',
        component: FiatToCoin,
        props: route => ({ walletAddress: route.query.walletAddress, fiatType: route.query.fiatType })
    },
    {
        path: '/sell',
        name: 'sell',
        component: CoinToFiat,
        props: route => ({ walletAddress: route.query.walletAddress, fiatType: route.query.fiatType })
    },
    {
        path: '/history',
        name: 'history',
        component: TransactionHistory,
        props: route => ({ walletAddress: route.query.walletAddress, fiatType: route.query.fiatType })
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router