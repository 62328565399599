<template>
    <div class="btcdirect-widget" style="overflow-y: auto"></div>
</template>

<script>
import Big from 'big.js'

export default {
    props: ['walletAddress', 'fiatType'],
    methods: {
        status() {
            return new Promise((resolve, reject) => {
                function message(event) {
                    console.log('payload response xumm:', event)
                    // window.removeEventListener("message", message)

                    if(event.reason === 'SIGNED') return resolve()
                    else return reject('Order Cancelled')
                }
                window.xapp.on('payload', message)
            })
        },
        async payloadResult(uuid) {
            if(!uuid) throw 'Can\'t fetch Transaction result'
            try {
                const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/payload/${uuid}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.xappJWTxumm}`
                    }
                })

                if(res.status < 200 || res.status > 299) {
                    throw `Status: ${res.status}`
                }

                const data = await res.json()
                return data

            } catch(e) {
                throw 'Error fetching Transaction result'
            }
        },
        async initPayload(payload) {
            try {
                const res = await fetch('https://xumm.app/api/v1/xapp-jwt/payload', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.xappJWTxumm}`
                    },
                    body: JSON.stringify(payload)
                })

                if(res.status < 200 || res.status > 299) {
                    throw `Status: ${res.status}`
                }

                const data = await res.json()
                return data.uuid
            } catch(e) {
                alert(`Error with posting Transaction ${e}`)
            }
        },
        async submitPayment(data) {
            console.log(data)

            const xrpToDrops = (xrp) => {
                try {
                    return Big(xrp).times(1000000).toFixed(0, 3).toString()
                } catch(e) {
                    console.error(e)
                    throw 'Amount is not a valid number'
                }
            }

            try {
                if(isNaN(data.amount) || isNaN(data.destinationTag)) throw 'Amount is not a valid number'
                if(data.amount <= 0 || data.destinationTag <= 0) throw 'Amount is not a valid number'
                if(data.userWalletAddress !== this.walletAddress) throw 'Address received from BTC Direct is different than from Xaman'
                if(data.currency !== 'XRP') throw 'Crypto Currency that BTC Direct expects is not XRP'

                const payload = {
                    TransactionType: 'Payment',
                    Account: data.userWalletAddress,
                    Amount: xrpToDrops(data.amount),
                    Destination: data.walletAddress,
                    DestinationTag: data.destinationTag
                }
                console.log(payload)
                
                const uuid = await this.initPayload({ txjson: payload, options: { expire: 2 }, custom_meta: { blob: { external_id: data.orderId }, instruction: `BTC Direct orderId: ${data.orderId}` } })
                window.xapp.openSignRequest({ uuid })
                await this.status()
                const result = await this.payloadResult(uuid)

                // Check if the coins are send on MAINNET
                // remove TX status: result.response.dispatched_result === 'tesSUCCESS' 
                if(result.response.dispatched_nodetype === 'MAINNET') {
                        window.btcdirect('transaction-id-confirmation', {
                        orderId: data.orderId,
                        transactionId: result.response.txid
                    })
                } else {
                    throw 'Check payment details and contact BTC Direct for Support'
                }

            } catch(e) {
                try {
                    window.btcdirect('coin-to-fiat-order-canceled')
                } catch(e) {
                    // eslint-disable-next-line
                }
                alert(e)
            }
        }
    },
    mounted() {
        (function (btc, d, i, r, e, c, t) {
        btc[r] = btc[r] || function() {
        (btc[r].q = btc[r].q || []).push(arguments)
        };
        c = d.createElement(i);
        c.id = r; c.src = e; c.async = true;
        c.type = 'module'; c.dataset.btcdirect = '';
        t = d.getElementsByTagName(i)[0];
        t.parentNode.insertBefore(c, t);
        })(window, document, 'script', 'btcdirect',
        'https://cdn.btcdirect.eu/coin-to-fiat/coin-to-fiat.js'
        );

        let returnUrl = new URL(process.env.VUE_APP_XAPP_DEEPLINK)
        const pathname = '/sell'
        returnUrl.searchParams.set('path', btoa(pathname))
        returnUrl.searchParams.set('walletAddress', this.walletAddress)
        returnUrl.searchParams.set('fiatType', this.fiatType)

        window.btcdirect('init', { token: process.env.VUE_APP_BTC_DIRECT_TOKEN, theme: window.xAppTheme, returnUrl: returnUrl, useSameWindowForPayment: false });

        window.btcdirect('wallet-addresses', {
            addresses: {
                address: this.walletAddress,
                currency: 'XRP'
            }
        })

        window.btcdirect('currencies', {
            crypto: 'XRP',
            fiat: this.fiatType
        })

        window.btcdirect('include-dca', { include: true })

        window.addEventListener('btcdirect-embeddable-coin-to-fiat-order-requested', (evt) => {
            console.log('event: btcdirect-embeddable-coin-to-fiat-order-requested', evt)
            const data = evt.detail
            this.submitPayment(data)
        })
    }
}
</script>

<style>
.btcdirect-widget-container {
    max-width: none !important;
    height: 100vh !important;
}
</style>
