import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

document.addEventListener('click', e => {
    const origin = e.target.closest(`a`);
    
    if (origin) {
        const href = origin.href;
        if(href) e.preventDefault();
        console.log(href);
        window.xapp.openBrowser({ url: href });
    }
}, true);

const _open = window.open
window.open = function(url, target, features) {
        console.log('triggered', url)

        _open(url, target, features)
        window.xapp.openBrowser({ url })
    }

createApp(App).use(router).mount('#app')
