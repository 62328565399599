<template>
    <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html, body {
    overscroll-behavior-y: none;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}
#app {
    height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
